@font-face {
  font-family: "Spectral-Extralight-Italic";
  src: url("fonts/Spectral-ExtraLightItalic.otf");
}
@font-face {
  font-family: "Spectral-Extralight";
  src: url("fonts/Spectral-ExtraLight.otf");
}
@font-face {
  font-family: "Spectral-Light";
  src: url("fonts/Spectral-Light.otf");
  font-style: normal;
}
@font-face {
  font-family: Grandmaster;
  src: url("fonts/hinted-Grandmaster-Black.eot"); /* IE9 Compat Modes */
  src: url("fonts/hinted-Grandmaster-Black.eot") format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/Grandmaster Black.woff2") format("woff2")
      format("woff"); /* Pretty Modern Browsers */
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Caspar";
  src: url(./fonts/Caspar-Condensed-Bold-v.0.1.otf) format("opentype");
}

@font-face {
  font-family: "RT Dromo Book";
  src: url(./fonts/RTDromo-Book.woff) format("woff");
}

body {
  background: black;
  color: #e7e7f2;
  text-rendering: geometricPrecision !important;
  font-smoothing: antialiased !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-stroke-width: 0 !important;
  font-weight: normal !important;
}
.App {
  width: 100%;
}
h1 {
  font-family: "Caspar", sans-serif;
  font-size: 600px !important;
  color: #e7e7f2;
  text-transform: uppercase;
  margin: 0px !important;
  line-height: 1;
}
h4 {
  font-family: "Caspar", sans-serif;
  font-size: 80px !important;
  color: #e7e7f2;
  line-height: 120px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
header {
  position: fixed;
  left: 32px;
  top: 32px;
  z-index: 1001 !important;
}
#wordmark {
  width: 300px;
}
#wordmark img {
  width: 100%;
}
#back {
  margin: 24px 0 0 0;
}
#back:hover {
  cursor: pointer;
}
.projectTitle,
.liveProjectLink a {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 48px !important;
  color: #cfd8dc !important;
}
.liveProjectLink {
  box-sizing: border-box;
}
.projectTitleRow:hover {
  cursor: pointer !important;
}
.projectTitleRow {
  display: flex !important;
}
.imageDescription {
  text-align: center;
  font-family: "RT Dromo Book", sans-serif;
  font-size: 16px !important;
  color: #cfd8dc !important;
}
a {
  text-decoration: none;
  display: block !important;
}
#heroImage {
  z-index: 1 !important;
}
.skrim {
  background-image: linear-gradient(
    187deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.91) 90%
  );
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
.projectDetailName {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 64px !important;
  line-height: 64px;
  color: #cfd8dc;
  font-weight: 100;
}
.z {
  z-index: 999 !important;
}
h2,
.projectDetailClient {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 40px !important;
  color: #cfd8dc;
  font-weight: 800;
}
h3 {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 28px !important;
  line-height: 40px;
  margin: 16px 0;
  color: #cfd8dc;
  font-weight: 100;
}
p {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 32px !important;
  line-height: 48px;
  color: #cfd8dc;
  margin: 8px 0 32px 0;
  font-weight: 100;
}
.p2 {
  font-family: "RT Dromo Book", sans-serif;
  font-size: 24px !important;
  line-height: 24px;
  color: #cfd8dc;
  font-weight: 100;
}
.projRowParent:last-child {
  border-bottom: 1px solid #cfd8dc !important;
}
.projRowParent {
  border-bottom: none !important;
}
.processSections img {
  margin: 8px 0;
  width: 100%;
}

#loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loader svg {
  width: 100%;
  padding: 10%;
}
.st0 {
  stroke: #cfd8dc;
  stroke-width: 24px;
  fill: transparent;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  animation: dash 3s ease;
  -wekbit-animation: dash 3s ease;
  animation-iteration-count: infinite;
}
@keyframes dash {
  0% {
    stroke-dashoffset: 4400;
    stroke-dasharray: 4400;
    animation-direction: forward;
  }
  50% {
    stroke-dashoffset: 00;
    stroke-dasharray: 4400;
    animation-direction: forward;
  }
  100% {
    stroke-dashoffset: -4400;
    stroke-dasharray: 4400;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 4400;
    stroke-dasharray: 4400;
    -wekbit-animation-direction: forward;
  }
  50% {
    stroke-dashoffset: 00;
    stroke-dasharray: 4400;
    -wekbit-animation-direction: forward;
  }
  100% {
    stroke-dashoffset: -4400;
    stroke-dasharray: 4400;
  }
}
@media only screen and (max-width: 64em) {
  h1 {
    font-size: 300px !important;
    margin: 0px !important;
    line-height: 1;
  }
  #wordmark {
    width: 250px;
  }
  .projectDetailClient {
    font-size: 32px !important;
  }
  .projectDetailName {
    font-size: 40px !important;
    line-height: 56px;
  }
}

@media only screen and (max-width: 32em) {
  h1 {
    font-size: 160px !important;
    margin: 0px !important;
    line-height: 1;
  }
  h2 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 64px !important;
    line-height: 72px;
  }
  .projectDetailClient {
    font-size: 24px !important;
  }
  .projectDetailName {
    font-size: 48px !important;
    line-height: 48px;
  }
  .projectTitle {
    font-size: 40px !important;
  }
  #wordmark {
    width: 150px;
  }
  p {
    font-size: 20px !important;
    line-height: 24px;
  }
  .App {
    padding: 0 16px;
  }
  .liveProjectLink a {
    font-family: "RT Dromo Book", sans-serif;
    font-size: 32px !important;
    color: #cfd8dc !important;
  }
}
